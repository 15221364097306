/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ref, useContext } from '@nuxtjs/composition-api';
import axios from 'axios';
import { InternalEndpoints } from '~/enums/internalEndpoints';
import { isClient } from '~/helpers/check-environment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { throttle } from 'lodash-es';
import { CustomerPermission } from '@gemini-commerce/typescript-client-customer';
import { EnrichCustomerReqData, RegisterCustomerBillingAddress } from '~/types/types';

const GEMINI_CLIENT_TIMEOUT = 10_000;

const parseResponse = (response: Record<string, unknown>) => {
  const { firstname, lastname } = JSON.parse(response.recipients[0]);
  return {
    firstname,
    lastname,
    street: response.addressLines,
    city: response.locality,
    postcode: response.postalCode,
    region: {
      region: response.administrativeArea,
      region_code: response.administrativeArea,
    },
    country_code: response.regionCode,
    telephone: response.phoneNumber,
  };
};

const useGeminiApi = () => {
  const geminiData = ref(null) as Record<string, unknown>;
  const loading = ref(false);
  const error = ref(null) as Record<string, unknown>;
  const {
    app: {
      $config: { ssrMiddlewareUrl, middlewareUrl },
    },
  } = useContext();

  const getCustomerPermissions = throttle(
    async (user): Promise<{ canBuy: boolean; canLogin: boolean }> => {
      try {
        const { data, status }: { data: string[]; status: number } = await axios.post(
          InternalEndpoints.GetCustomerPermissions,
          { userId: user?.uid },
          {
            baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
            timeout: GEMINI_CLIENT_TIMEOUT,
          }
        );
        if (status === 200) {
          return {
            canBuy: data.includes(CustomerPermission.Buy),
            canLogin: data.includes(CustomerPermission.Login),
          };
        }
      } catch (getCustomerPermissionsJSError) {
        console.warn('getCustomerPermissions ~ caught error:', getCustomerPermissionsJSError);
      }
      return {
        canBuy: false,
        canLogin: false,
      };
    },
    2000,
    { trailing: false }
  );

  const getAdditionalOrderData = async (orderId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data, status } = await axios.get(InternalEndpoints.GetAdditionalOrderData, {
      params: {
        orderId,
      },
      baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
      timeout: GEMINI_CLIENT_TIMEOUT,
    });
    if (status === 200) {
      try {
        geminiData.value = {
          shipping: data.shippingAddress ? parseResponse(data.shippingAddress) : null,
          billing: data.billingAddress ? parseResponse(data.billingAddress) : null,
        };
      } catch (e) {
        error.value = e;
      }
      return;
    }
    error.value = data;
  };

  const getGeminiOrderNotes = async (orderNumber: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data, status } = await axios.get(InternalEndpoints.GetOrderInfoAfterPlacement, {
      params: {
        orderNumber,
      },
      baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
      timeout: GEMINI_CLIENT_TIMEOUT,
    });
    if (status === 200) {
      return data;
    }
    console.error('getGeminiOrderNotes ~ error:', data);
    return null;
  };

  const addCustomerGroupToCustomer = async (groupId: string, customerId: string) => {
    try {
      const { data, status } = await axios.post(
        InternalEndpoints.AddCustomerGroupToCustomer,
        {
          groupId,
          customerId,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      if (status === 200) {
        return data;
      }
      error.value = data;
    } catch (e) {
      error.value = e;
    }
    return null;
  };

  const enrichCustomer = async (enrichCustomerReqData: EnrichCustomerReqData) => {
    try {
      const { data, status } = await axios.post(
        InternalEndpoints.EnrichCustomer,
        {
          enrichCustomerReqData,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      if (status === 200) {
        return data;
      }
      error.value = data;
    } catch (e) {
      error.value = e;
    }
    return null;
  };

  const getCustomerDestinazioniDiverse = async (customerCode: string) => {
    try {
      const { data, status } = await axios.post(
        InternalEndpoints.GetCustomerDestinazioniDiverse,
        {
          customerCode,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      if (status === 200) {
        return data;
      }
      error.value = data;
    } catch (e) {
      error.value = e;
    }
    return null;
  };

  const registerCustomerInEmbyon = async (customerData, billingAddress: RegisterCustomerBillingAddress) => {
    try {
      const { data, status } = await axios.post(
        InternalEndpoints.RegisterCustomerInEmbyon,
        {
          customerData,
          billingAddress,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      if (status === 200) {
        return data;
      }
      error.value = data;
    } catch (e) {
      error.value = e;
    }
    return null;
  };

  // data structure is
  // { "XYZ": { "stock": { "StockTreviso": -88, "StockVarese": 0 } }, "ASD": { "stock": { "StockTreviso": 90, "StockVarese": 0 } } }
  const getStockData = async (skus: []) => {
    if (!skus || skus.length === 0) {
      return null;
    }
    loading.value = true;
    try {
      const { data } = await axios.post(
        InternalEndpoints.GetStockData,
        {
          skus,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      return data;
    } catch (e) {
      console.error(e);
      error.value = e;
    }
    loading.value = false;
    return null;
  };

  const getNppBySkus = async (skus: [], user) => {
    loading.value = true;
    try {
      if (!user?.additional_info) return null;
      const { codiceCliente: companyId } = JSON.parse(user.additional_info);
      if (!skus || skus.length === 0) {
        return null;
      }
      const { data } = await axios.post(
        InternalEndpoints.GetNppBySkus,
        {
          skus,
          companyId,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      return data;
    } catch (e) {
      console.error(e);
    }
    loading.value = false;
    return null;
  };

  const getProductPriceHistory = async (productCode: string, user) => {
    loading.value = true;
    try {
      if (!user?.additional_info) return null;
      const { codiceCliente: companyId } = JSON.parse(user.additional_info);
      if (!productCode) return false;
      const { data } = await axios.post(
        InternalEndpoints.GetProductPriceHistory,
        {
          productCode,
          companyId,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      return data;
    } catch (e) {
      console.error(e);
    }
    loading.value = false;
    return null;
  };

  const getOrderBudget = async (CodiceCliente: string) => {
    loading.value = true;
    try {
      if (!CodiceCliente) return null;
      const {
        data: { budget },
      } = await axios.post(
        InternalEndpoints.GetOrderBudget,
        {
          CodiceCliente,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      return budget;
    } catch (e) {
      console.error(e);
    }
    loading.value = false;
    return null;
  };

  const getContestToken = async (customerCode: string): Promise<string | null> => {
    const { data, status } = await axios.post(
      InternalEndpoints.GetContestToken,
      {
        customerCode,
      },
      {
        baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
        timeout: GEMINI_CLIENT_TIMEOUT,
      }
    );
    if (status === 200) {
      return data;
    }
    return null;
  };

  const getAdventureToken = async (customerCode: string): Promise<string | null> => {
    const { data, status } = await axios.post(
      InternalEndpoints.GetAdventureToken,
      {
        customerCode,
      },
      {
        baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
        timeout: GEMINI_CLIENT_TIMEOUT,
      }
    );
    if (status === 200) {
      return data;
    }
    return null;
  };

  const getExtranetGuid = async (email: string): Promise<string | null> => {
    const { data, status } = await axios.post(
      InternalEndpoints.GetExtranetGuid,
      {
        email,
      },
      {
        baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
        timeout: GEMINI_CLIENT_TIMEOUT,
      }
    );
    if (status === 200) {
      return data;
    }
    return null;
  };

  const getPayloadData = async (collectionName: string, id: string) => {
    try {
      const { data, status } = await axios.post(
        InternalEndpoints.GetPayloadData,
        {
          collectionName,
          id,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (getPayloadDataError) {
      console.error('getPayloadData ~ error:', getPayloadDataError);
    }
    return null;
  };

  const getBemilsUrl = async (vat: string) => {
    const { data, status } = await axios.post(
      InternalEndpoints.GetBemilsUrl,
      {
        vat,
      },
      {
        baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
        timeout: GEMINI_CLIENT_TIMEOUT,
      }
    );
    if (status === 200) {
      return data;
    }
    return null;
  };

  const deleteWishlist = async (wishlistId: string) => {
    try {
      const { data, status } = await axios.post(
        InternalEndpoints.DeleteWishlist,
        {
          wishlistId,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      if (status === 200) {
        return data;
      }
      error.value = data;
    } catch (e) {
      error.value = e;
    }
    return null;
  };

  const shareWishlist = async (wishlistId: string, permission: string, customerAggregationId: string) => {
    const { status } = await axios.post(
      InternalEndpoints.ShareWishlist,
      {
        wishlistId,
        permission,
        customerAggregationId,
      },
      {
        baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
        timeout: GEMINI_CLIENT_TIMEOUT,
      }
    );
    if (status === 200) return true;
    return false;
  };

  const sendToAgent = async (
    agentMail: string,
    subject: string,
    text: string,
    from: string,
    firstname: string,
    lastname: string,
    isCartReview: boolean
  ): Promise<boolean> => {
    try {
      const { status } = await axios.post(
        InternalEndpoints.SendMailToAgent,
        {
          agentMail,
          subject,
          text,
          from,
          firstname,
          lastname,
          isCartReview,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      if (status === 200) return true;
    } catch (sendToAgentError) {
      console.error('useGeminiApi ~ sendToAgent:', sendToAgentError);
    }
    return false;
  };

  const getAgentByCode = async (user) => {
    try {
      const { codiceAgente } = JSON.parse(user.additional_info);
      const { data, status } = await axios.post(
        InternalEndpoints.GetAgentByCode,
        {
          agentCode: codiceAgente,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (getAgentError) {
      console.error('getAgentError ~ error:', getAgentError);
    }

    return null;
  };

  const validateRecaptcha = async (token: string) => {
    try {
      const {
        data: { success },
        status,
      } = await axios.post(
        InternalEndpoints.ValidateRecaptcha,
        {
          token,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      return status === 200 && success;
    } catch (e) {
      console.error('validateRecaptcha ~ error:', e);
    }
    return null;
  };

  const isCustomerInRole = async (role: string, companyId: string) => {
    try {
      const body = {
        role,
        companyCode: companyId,
      };

      const { data, status } = await axios.post(InternalEndpoints.IsCustomerInRole, body, {
        baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
        timeout: GEMINI_CLIENT_TIMEOUT,
      });
      if (status === 200) {
        return data;
      }
    } catch (isCustomerInRoleError) {
      console.error('isCustomerInRole ~ error:', isCustomerInRoleError);
    }

    return null;
  };

  const listAttributeOptions = async (attributeCode: string) => {
    try {
      const { data, status } = await axios.post(
        InternalEndpoints.ListAttributeOptions,
        {
          attributeCode,
        },
        {
          baseURL: isClient ? middlewareUrl : ssrMiddlewareUrl,
          timeout: GEMINI_CLIENT_TIMEOUT,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (e) {
      console.error('listAttributeOptions ~ error:', e);
    }
    return null;
  };

  return {
    geminiData,
    loading,
    error,
    getAdditionalOrderData,
    getCustomerPermissions,
    getStockData,
    addCustomerGroupToCustomer,
    getContestToken,
    getAdventureToken,
    getPayloadData,
    enrichCustomer,
    registerCustomerInEmbyon,
    getBemilsUrl,
    shareWishlist,
    sendToAgent,
    getAgentByCode,
    getNppBySkus,
    getProductPriceHistory,
    getOrderBudget,
    validateRecaptcha,
    getExtranetGuid,
    deleteWishlist,
    isCustomerInRole,
    listAttributeOptions,
    getGeminiOrderNotes,
    getCustomerDestinazioniDiverse,
  };
};

export default useGeminiApi;
